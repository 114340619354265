<template>
  <div :class="containerClass" @click="onWrapperClick" v-if="!isLoading">
    <TheSideBar v-if="isLoggedIn" @click="onSidebarClick" v-show="isSidebarVisible()" :class="sidebarClass" @help-toggle="onHelpToggle" @menu-toggle="onMenuToggle" />
    <FeedSideBar v-if="isLoggedIn" v-show="isFeedSidebarVisible()" :reload="feedSidebarOpen == true" :class="feedSidebarClass" @feed-toggle="onFeedMenuToggle" />
    <template v-if="isLoggedIn">
      <template v-if="!isNativePlatform() || isCme(loggedInUser)">
        <NavBar @menu-toggle="onMenuToggle" @help-toggle="onHelpToggle" :class="topBarClass" @feed-toggle="onFeedMenuToggle" />
      </template>
      <template v-else-if="$route.name != 'RecentCallsHistory' && $route.name != 'ManageCallDisplayNames' && $route.name != 'AddEncounterNote' && $route.name != 'EditEncounterNote' && $route.name != 'Notes'">
        <NavBar @menu-toggle="onMenuToggle" @help-toggle="onHelpToggle" :class="topBarClass" @feed-toggle="onFeedMenuToggle" />
      </template>
    </template>
    <PublicNavBar v-if="!isLoggedIn && !isNativePlatform()" @openPublicSideBar="openPublicSideBar" />
    <PublicSideBar v-if="!isLoggedIn" @togglePublicSideBar="togglePublicSideBar" :openSideBar="openSideBar" />
    <div class="layout-main" :class="{ 'layout-feed-sidebar-open': feedSidebarOpen, 'pb-20': isNativePlatform() }">
      <!-- <ion-content v-if="isNativePlatform()" class="ion-content-with-refresher">
        <ion-refresher ref="refresher" slot="fixed" @ionRefresh="handleRefresh($event)">
          <ion-refresher-content refreshing-spinner=""></ion-refresher-content>
        </ion-refresher>

        <router-view> </router-view>
        <div class="h-40"></div>
      </ion-content>
      <router-view v-else></router-view> -->
      <router-view> </router-view>

      <Toast position="bottom-right" />
    </div>
    <PublicFooter v-if="!isLoggedIn && !isNativePlatform()" />
  </div>

  <div class="flex flex-col items-center justify-center h-screen bg-white animated fade-in-up" v-else>
    <img src="@/assets/Virtual-Hallway-Logo.png" alt="" class="h-14" />
  </div>

  <ConfirmIdleActivity :show="popUpConfirmIdle" :countDownLimit="secondsLeftToConfirmIdleActivity - 30" @confirmActivityPopUpClosed="confirmActivityPopUpClosed" v-if="isLoggedIn" />
  <Sidebar v-model:visible="toggleHelp" :baseZIndex="1000" position="right" id="help-sidebar" @hide="onHelpHide">
    <template #header>
      <div class="flex flex-row items-center font-bold text-gray-400">
        <i class="pi pi-question-circle navbar-icon"></i>
        <h2 class="ml-2 text-2xl text-gray-400 text-semibold">Help Center</h2>
      </div>
    </template>
    <div class="flex flex-col justify-between space-between">
      <div class="flex flex-col">
        <div class="mt-2">
          <SidebarHelpCenter @toggleHelp="onHelpToggle" />
        </div>
      </div>
    </div>
  </Sidebar>
</template>

<script>
import NavBar from './components/ui_layout/TheNavBar.vue';
import Button from 'primevue/button';
import Sidebar from 'primevue/sidebar';
import TheSideBar from './components/ui_layout/TheSideBar.vue';
import SidebarHelpCenter from './components/support/SidebarHelpCenter.vue';
import FeedSideBar from './components/ui_layout/TheFeedSideBar.vue';
import PublicNavBar from './components/ui_layout/PublicNavBar.vue';
import PublicSideBar from './components/ui_layout/PublicSideBar.vue';
import PublicFooter from './components/ui_layout/PublicFooter.vue';
import ConfirmIdleActivity from './components/misc/ConfirmIdleActivityDialog.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import CapacitorCallPlugin from '@/plugins/CapacitorCallPlugin';
// import { IonContent, IonHeader, IonRefresher, IonRefresherContent } from '@ionic/vue';
import { Preferences } from '@capacitor/preferences';
import { App } from '@capacitor/app';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { PushNotifications } from '@capacitor/push-notifications';
import globals from './globals';

// import { App } from '@capacitor/app';
// import * as LiveUpdates from '@capacitor/live-updates';

export default {
  inject: ['mixpanel'],
  components: {
    Button,
    NavBar,
    TheSideBar,
    Sidebar,
    FeedSideBar,
    PublicNavBar,
    PublicFooter,
    PublicSideBar,
    ConfirmIdleActivity,
    SidebarHelpCenter,
    // IonContent,
    // IonHeader,
    // IonRefresher,
    // IonRefresherContent,
  },
  data() {
    return {
      layoutMode: 'static',
      layoutColorMode: 'dark',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      openSideBar: false,
      feedSidebarOpen: false,
      popUpConfirmIdle: false,
      secondsSinceLastTokenRefreshAcrossTabs: null,
      secondsLeftToConfirmIdleActivity: 300,
      toggleHelp: false,
      isLoading: false,
    };
  },
  created() {
    if (window.location.hash == '#nativePlatformGoToRecentCallsHistory') {
      this.$router.push('/recent-calls-history');
    } else if (window.location.hash == '#nativePlatformGoToLectures') {
      this.$router.push('/lectures');
    }
    this.initEcho();
  },
  async mounted() {
    if (this.isNativePlatform()) {
      // const refresherElement = this.$refs.refresher.$el;
      // refresherElement.addEventListener('ionRefresh', () => {
      //   this.handleRefresh();
      // });

      // This is to only show the splash screen when user is opening the app in longer intervals. Otherwise, for frequent resuming to the application
      // the splash screen and the loading screens appear providing a laggy experience.
      if (!this.isLoggedIn) {
        this.isLoading = true;
      }
      try {
        await this.nativeAppSetup();
      } catch {}
    }
    setTimeout(this.countdown, 1);
    window.addEventListener('beforeunload', this.beforeWindowUnload);
    if (!import.meta.env.VITE_MIXPANEL_ACCESS_TOKEN) {
      console.log('mixpanel disabled');
      this.mixpanel.disable();
    } else if (this.loggedInUser && (this.loggedInUser.cpsns === 'demo' || this.loggedInUser.demo_account)) {
      this.mixpanel.disable();
    }
  },
  methods: {
    ...mapActions(['refreshToken', 'getAcsAccessToken', 'createOrUpdateDisplayName', 'createUserFeedback', 'acsSaveCallId', 'getLoggedInUser', 'saveCallNetworkDiagnostic', 'saveCallMediaDiagnostic', 'saveDeviceVoipToken', 'saveMediaStatisticsReport', 'saveUserAgreedToPushNotifications']),
    ...mapMutations(['setShowTutorialRelaunchTooltip', 'setAccessToken', 'setDeviceVoipToken']),
    handleRefresh() {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
    countdown() {
      if (this.isLoggedIn) {
        // If document is hidden, do not to check for additional validity to refresh token since it
        // might be minimized or another tab. If it is in another tab, it also prevents prompting
        // confirm activity modal since if that modal is unattended, it will logout the user after 5
        // minutes, so user will be logged out from other tab while they may be active in the current tab.
        if (!document.hidden || this.isNativePlatform() || this.isLecturePlaying) {
          let lastTokenRefreshTimeAcrossTabs = Date.parse(localStorage.getItem('timeFromStorageSinceLastTokenRefresh'));
          this.secondsSinceLastTokenRefreshAcrossTabs = Math.ceil((new Date().getTime() - lastTokenRefreshTimeAcrossTabs) / 1000);
          // The secondsSinceLastTokenRefreshAcrossTabs is NaN when a user is (auto) logged out. Thus, check if it is an invalid value
          // then reload the page which would redirect them to login page.
          if (!this.secondsSinceLastTokenRefreshAcrossTabs) {
            this.$router.replace({ name: 'Logout', query: { autoLogout: 'true', redirect: this.$route.path } });
          } else if (this.secondsSinceLastTokenRefreshAcrossTabs >= this.tokenLifeTimeInSeconds - this.secondsLeftToConfirmIdleActivity) {
            // If user is idle or if the mobile app is not open, do not attempt refreshing the token. The mobile app can be open while the web app state is idle, e.g.
            // when the user is on a call the native app part is open and our web app is idle.
            if (this.isAppIdle && !this.isMobileAppActive && !this.isLecturePlaying) {
              this.popUpConfirmIdle = true;
            } else {
              // This is a hacky fix that will be amended in the next week
              this.refreshToken()
                .then(() => {
                  this.popUpConfirmIdle = false;
                })
                .catch(() => {
                  this.$router.replace({ name: 'Logout', query: { autoLogout: 'true', redirect: this.$route.path } });
                });
            }
          }
        }
      }
      // Recall this method every 20 seconds. This can be further optimized to be a variable
      // and be called every minute until the confirmIdleActivity modal is popped up, if it
      // is popped then call this countdown more frequently. Sticking to 20s for now.
      setTimeout(this.countdown, 20000);
    },
    openPublicSideBar() {
      this.openSideBar = true;
    },
    togglePublicSideBar(event) {
      this.openSideBar = event;
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onHelpToggle() {
      this.toggleHelp = !this.toggleHelp;
      this.setShowTutorialRelaunchTooltip(false);
    },
    onHelpHide() {
      this.$store.commit('setOpenedSupportSectionKnowledgebaseAccordionIndex', null);
      this.$store.commit('setOpenedSupportSectionAccountAccordionIndex', null);
    },
    onMenuToggle() {
      this.menuClick = true;

      if (!this.isDesktop()) {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onFeedMenuToggle() {
      this.feedSidebarOpen = !this.feedSidebarOpen;
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static') return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay') return this.overlayMenuActive;
        else return true;
      } else {
        return true;
      }
    },
    //This function merely v-shows the sidebar on mobile, it does not modulate the container classes
    isFeedSidebarVisible() {
      if (this.isDesktop()) {
        // The "staticFeedMenuInactive" wasn't getting used anywhere as it seems to turned it off.
        // if (this.layoutMode === 'static') return !this.staticFeedMenuInactive;
        if (this.layoutMode === 'overlay') return this.overlayFeedMenuActive;
        else return true;
      } else {
        return true;
      }
    },
    confirmActivityPopUpClosed() {
      this.popUpConfirmIdle = false;
    },
    beforeWindowUnload(e) {
      if (this.loggedInUser && this.referral && this.$route.name === 'ReferralDetail' && this.canEditReferralForm(this.loggedInUser, this.referral.referee, this.referral) && this.referral.status === 'Pending' && this.referral.id !== this.savedReferralId) {
        e.preventDefault();
        e.returnValue = '';
      }
    },
    initEcho() {
      if (this.loggedInUser?.scribe_opt_in) {
        // connect to the channel
        window.Pusher = Pusher;
        window.Echo = new Echo({
          broadcaster: 'pusher',
          authEndpoint: globals.APIs.mainApiUrl() + import.meta.env.VITE_PUSHER_AUTH_ENDPOINT,
          key: import.meta.env.VITE_PUSHER_APP_KEY,
          cluster: import.meta.env.VITE_PUSHER_CLUSTER,
          auth: {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
            },
          },
        });
      }
    },
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'loggedInUser', 'isOrgUnitHead', 'referral', 'canEditReferralForm', 'tokenLifeTimeInSeconds', 'timeFromStorageSinceLastTokenRefresh', 'savedReferralId', 'isPreboarding', 'hasValidAcsToken', 'callDisplayNames', 'openedSupportSectionKnowledgebaseAccordionIndex', 'openedSupportSectionAccountAccordionIndex', 'isMobileAppActive', 'isCme', 'accessToken', 'isLecturePlaying']),
    containerClass() {
      if (this.isLoggedIn) {
        return [
          'layout-wrapper',
          {
            'layout-overlay': this.layoutMode === 'overlay',
            'layout-static': this.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.mobileMenuActive,
          },
        ];
      } else {
        return null;
      }
    },
    sidebarClass() {
      return [
        'layout-sidebar',
        {
          'layout-sidebar-dark': this.layoutColorMode === 'dark',
          'layout-sidebar-light': this.layoutColorMode === 'light',
        },
      ];
    },
    feedSidebarClass() {
      return [
        'layout-feed-sidebar',
        {
          'layout-feed-sidebar-closed': this.feedSidebarOpen === false,
        },
        {
          'layout-mobile-feed-sidebar-active': this.feedSidebarOpen === true,
        },
      ];
    },
    topBarClass() {
      return [
        {
          'layout-topbar-feed-sidebar-open': this.feedSidebarOpen === true,
        },
      ];
    },
    logo() {
      return this.layoutColorMode === 'dark' ? 'assets/layout/images/logo-white.svg' : 'assets/layout/images/logo.svg';
    },
    async nativeAppSetup() {
      this.initEcho();
      CapacitorCallPlugin.setGlobalVariables({ mainApiUrl: globals.APIs.mainApiUrl() });
      CapacitorCallPlugin.setIsLoggedIn({ isLoggedIn: this.isLoggedIn });
      // Mobile app activity can be determined using Capacitor. When the app is open the app state is active, when the app goes out
      // of view (i.e. switching to other app or locking screen) it becomes inactive.
      this.$store.commit('setIsMobileAppActive', true);
      // App.addListener('appStateChange', ({ isActive }) => {
      //   this.$store.commit('setIsMobileAppActive', isActive);
      // });

      // App.addListener('resume', async () => {
      //   if (localStorage.shouldReloadApp) {
      //     await LiveUpdates.reload();
      //   } else {
      //     const result = await LiveUpdates.sync();
      //     localStorage.shouldReloadApp = result.activeApplicationPathChanged;
      //   }
      // });
      // First sync on app load
      // const result = await LiveUpdates.sync();
      // localStorage.shouldReloadApp = result.activeApplicationPathChanged;

      const isLoggedIn = this.isLoggedIn;
      App.addListener('appUrlOpen', function (event) {
        const path = event.url.split(':/').pop();
        if (path) {
          // This is to prevent CME, Notes and Recents tabs in mobile app to not also route to given URL. We only want to redirect in the Consults tab.
          if (!window.location.pathname.includes('recent-calls') && !window.location.pathname.includes('lecture') && !window.location.pathname.includes('note')) {
            if (isLoggedIn && path.includes('?redirect=')) {
              const redirect = path.split('?redirect=').pop();
              window.location.href = redirect;
            } else {
              window.location.href = path;
            }
          }
        }
      });

      if (this.isLoggedIn) {
        CapacitorCallPlugin.setCallDisplayNames({ callDisplayNames: this.callDisplayNames });
        CapacitorCallPlugin.setLoggedInUser({ loggedInUser: { ...this.loggedInUser, display_caller_welcome_modal: this.loggedInUser.actions?.find((action) => action.name === 'welcome_to_caller')?.status === 'Active' }, authAccessToken: this.accessToken });
      }

      window.addEventListener('addDisplayNameEvent', (data) => {
        const requestData = {
          user_id: this.loggedInUser.id,
          display_name: data.displayName,
          is_default: data.isDefault === 'true',
        };
        this.createOrUpdateDisplayName(requestData)
          .then(() => {})
          .catch(() => {});
      });

      window.addEventListener('submitCallFeedbackEvent', (data) => {
        const requestData = {
          user_id: this.loggedInUser.id,
          entity_type: data.entityType,
          entity_type_id: data.entity_type_id ? data.entity_type_id : null,
          meta_data: JSON.stringify({ starRating: data.starRating, reviewText: data.reviewText }),
        };
        this.createUserFeedback(requestData)
          .then(() => {})
          .catch(() => {});
      });

      window.addEventListener('capacitorEventCallKitCallStarted', (data) => {
        let callData = {
          call_id: data.callId,
          caller_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          scribe_opt_in: data.scribe_opt_in === 'true',
        };

        if (data.referralId) {
          callData.referral_id = data.referralId;
        }

        // callGroupId not being null means it is a VoIP call
        if (data.callGroupId) {
          callData.call_group_id = data.callGroupId;
        } else {
          callData.callee_phone_number = data.callee_phone_number;
        }
        this.acsSaveCallId(callData)
          .then(() => {})
          .catch(() => {});
      });

      window.addEventListener('routeToPath', (data) => {
        this.$router.push(data.path);
      });

      window.addEventListener('mixpanelEvent', (data) => {
        this.mixpanel.track(data.event);
      });

      window.addEventListener('nativePlatformCmeTabOpened', () => {
        // When users get auto logged out and get redirected  to login page, different tabs on the mobile app may still
        // be in the login page while user has logged in. So, we make sure the user is always redirected to proper
        // pages for the tabs.
        // However, local storage is not reactive across multiple tabs. After testing it was found that, only
        // a page reload on the current tab was updating the access tokens when a login was performed
        // on another tab. Thus, we need to refresh the page when user is redirected to login page due to
        // auto logout.
        // After the refresh, we need to redirect the user back to the default tab route. We do this via passing
        // a hash with page reload.
        if (this.$route.name != 'Lectures' && this.$route.name != 'SingleLecture' && this.$route.name != 'CmeCredits') {
          window.location.hash = 'nativePlatformGoToLectures';
          window.location.reload();
        }
      });

      window.addEventListener('nativePlatformRecentCallsHistoryTabOpened', () => {
        // Same reason as nativePlatformCmeTabOpened
        if (this.$route.name != 'RecentCallsHistory') {
          window.location.hash = 'nativePlatformGoToRecentCallsHistory';
          window.location.reload();
        }
      });

      window.addEventListener('updateUserTutorialByNameEvent', (event) => {
        this.updateUserTutorialByName(this.loggedInUser, event.tutorialName, false, event.status);
      });

      window.addEventListener('refreshAcsTokenEvent', () => {
        this.getAcsAccessToken()
          .then(() => {})
          .catch(() => {});
      });

      window.addEventListener('mixpanelEvent', (data) => {
        this.mixpanel.track(data.event);
      });

      window.addEventListener('saveDeviceVoipTokenEvent', (data) => {
        this.saveDeviceVoipToken(data)
          .then(() => {})
          .catch(() => {});
      });

      window.addEventListener('setAccessTokenEvent', async () => {
        const { value } = await Preferences.get({ key: 'accessToken' });
        this.setAccessToken({ access_token: value });
      });

      window.addEventListener('callDiagnosticEvent', (data) => {
        const json = data.event;
        const request = JSON.parse(json);

        if (request.diagnosticType == 'MediaDiagnostics') {
          this.saveCallMediaDiagnostic(request)
            .then(() => {})
            .catch(() => {});
        } else if (request.diagnosticType == 'NetworkDiagnostics') {
          this.saveCallNetworkDiagnostic(request)
            .then(() => {})
            .catch(() => {});
        } else if (request.diagnosticType == 'MediaStatisticsReport') {
          this.saveMediaStatisticsReport(request)
            .then(() => {})
            .catch(() => {});
        }
      });

      window.addEventListener('saveAgreedToPushNotificationsEvent', (data) => {
        this.saveUserAgreedToPushNotifications(data)
          .then(() => {})
          .catch(() => {});
      });

      if (this.isCme(this.loggedInUser)) {
        this.$router.push('/lectures');
      }
      this.isLoading = false;

      await PushNotifications.addListener('registration', (token) => {});

      await PushNotifications.addListener('registrationError', (err) => {});

      await PushNotifications.addListener('pushNotificationReceived', (notification) => {});

      await PushNotifications.addListener('pushNotificationActionPerformed', async (notification) => {
        if (notification?.notification?.data?.aps?.alert?.data?.route) {
          let route = notification?.notification?.data?.aps?.alert?.data?.route;
          await CapacitorCallPlugin.routeToConsultsTab({});
          this.$router.push(route);
        }
      });

      return true;
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive) this.addClass(document.body, 'body-overflow-hidden');
    else this.removeClass(document.body, 'body-overflow-hidden');
  },
  watch: {
    //Disables Feed Sidebar and associated classes when logged out, and when on the following routes
    $route() {
      if (!this.isLoggedIn || this.isDemoAccount(this.loggedInUser) || this.isOrgUnitHead(this.loggedInUser) || this.isPreboarding(this.loggedInUser) || this.$route.name == 'Referral' || this.$route.name == 'ShareConsults' || this.$route.name == 'ReferralDetail' || this.$route.name == 'MakeReferral' || this.$route.name == 'ReferralHistory' || this.$route.name == 'ManageUsers' || this.$route.name == 'Verification' || this.$route.name == 'Lectures' || this.$route.name == 'SingleLecture' || this.$route.name == 'CmeCredits' || this.$route.name == 'PoemsCmeCredits' || this.$route.name == 'SmartPathTransactions' || this.$route.name == 'SmartPaths' || this.$route.name == 'SmartPathBuilder' || this.$route.name == 'SmartPathTransactionHistory' || this.$route.name == 'SmartPathSurvey' || this.$route.name == 'AccountSetupPage' || this.$route.name == 'Billing' || this.$route.name == 'Resources' || !this.isDesktop() || this.$route.name == 'AddEncounterNote' || this.$route.name == 'EditEncounterNote' || this.$route.name === 'Notes' || this.$route.name === 'ShowMyAccount' || this.$route.name === 'CloseAccount' || this.$route.name == 'Poems' || this.$route.name == 'ViewPoem') {
        this.feedSidebarOpen = false;
      } else {
        this.feedSidebarOpen = true;
      }
    },
    isLoggedIn() {
      if (this.isNativePlatform()) {
        CapacitorCallPlugin.setIsLoggedIn({ isLoggedIn: this.isLoggedIn });
      }
      this.initEcho();
    },
    openedSupportSectionKnowledgebaseAccordionIndex(newVal, oldVal) {
      if (newVal !== null) {
        this.toggleHelp = true;
      } else {
        this.toggleHelp = false;
      }
    },
    openedSupportSectionAccountAccordionIndex(newVal, oldVal) {
      if (newVal !== null) {
        this.toggleHelp = true;
      } else {
        this.toggleHelp = false;
      }
    },
  },
};
</script>

<style>
@import '/resources/app.css';

.main-inner-wrap {
  padding: 60px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  min-height: 102vh;
  padding-bottom: 100px;
  overflow: hidden;
}

.main-inner-wrap--white-bg {
  background-color: var(--vh-white);
}

.shadow {
  box-shadow: 0px 3px 7px 0px rgb(132 132 132 / 10%);
}

#help-sidebar {
  overflow-y: scroll;
}

.p-sidebar {
  width: 30rem;
}

.p-sidebar-content {
  overflow: visible;
}

@media (max-width: 500px) {
  .p-sidebar {
    width: 100%;
  }
  /* .main-inner-wrap {
    padding: 50px 15px;
  } */
}

.pwa-bottom-nav {
  display: none;
}
/* fix this */
/* @media (display-mode: standalone) { */
.pwa-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  will-change: transform;
  transform: translateZ(0);
  display: flex;
  height: 90px;
  box-shadow: 0 -2px 5px -2px #c0c0c0;
  background-color: #fff;
}

.bottom-router-links > .router-link-exact-active > div {
  background: #f0f2f4;
}

.ion-content-with-refresher {
  display: block;
  position: absolute;
  width: 100%;
  /* height: 80vh; */
  z-index: 1; /* Set an appropriate z-index */
}

.ion-content-scroll-host {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

/* } */
</style>
